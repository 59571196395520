import styled, { css, themeGet, device } from "@styled";

export const IntroWrapper = styled.section`
  padding: 60px 0 100px;
  ${device.medium} {
    padding: 100px 0 150px;
  }
  ${(props) =>
    props.layout === 1 &&
    css`
      background-color: #fff;
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      background-color: #f8f8f8;
    `}
    ${(props) =>
    props.layout === 3 &&
    css`
    background: #fff;
    padding-bottom:20px !important;
  `}
  ${(props) =>
    props.layout === 4 &&
    css`
      background-color: #f8f8f8;
      padding:40px 0;
      ${device.medium}{
        padding:60px 0;
      }
      ${device.large}{ 
        padding:80px 0;
      }
      ${device.xlarge}{ 
        padding:100px 0;
      }
   `}
`;

export const LeftBox = styled.div`
  ${device.medium} {
    margin-bottom: 20px;
  }
  ul {
    ${device.small} {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: repeat(7, auto);
    }
  }
`;

export const Styledtitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: ${themeGet("colors.primary")};
`;
export const RightBox = styled.div`
  margin: 15px 0;
  ${device.large} {
    margin: 0;
  }
  
  video {
    margin-bottom: 10px;
    width: 500px;
    height: 300px;
    object-fit: cover;

    @media (max-width: 1400px) {
      width: 450px;
      height: 250px;
    }

    @media (max-width: 1199.98px) {
      width: 400px;
      height: 200px;
    }

    @media (max-width: 991.98px) {
      width: 100%;
      height: 300px;
    }
  }
`;

export const LogosWrap = styled.div`
  margin-top: 50px;
`;

export const LogoBox = styled.div`
a {
  text-align: center;
  font-weight: 400;
  color: ${themeGet("colors.primary")};
}
.gatsby-image-wrapper {
  background: #fff;
  padding: 10px;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  box-shadow: ${themeGet("shadows.custom")};
  img {
    object-fit: contain !important;
    max-width: 200px;
    inset: auto;
  }
}
`;
export const BottomText = styled.p`
    font-size:18px;
    color: ${themeGet("colors.primary")};
    font-weight:600;
    margin:30px 0 0;
    letter-spacing: 0.5px;
    ${device.medium} {
      font-size:20px;
      margin:60px 0 0;
    }
    ${device.xlarge} {
      font-size:22px;
      margin:80px 0 0;
    }
`;
